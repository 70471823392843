import React, { useState } from "react";
//https://www.pexels.com/video/aerial-view-of-basketball-courts-in-los-angeles-8783380/
//https://www.pexels.com/video/high-angle-shot-of-two-tennis-courts-6808191/
import { Button } from "./ButtonElement";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <HeroContainer id="hero">
        <HeroBg id="heroBg">
          <VideoBg
            playsInline
            autoPlay
            loop
            muted
            src="https://checkmypark-video.s3.amazonaws.com/bball_video.mp4"
            type="video/mp4"
          />
          {/*  <VideoBg src="bball-game-cover-2.jpg" alt="Check My Court logo" /> */}
        </HeroBg>
        <HeroContent>
          <img src="CheckMyPark_Logo_trans.png" alt="Check My Park logo" />
          {/* <HeroP>Check park asset availability in real time</HeroP> */}
          <HeroBtnWrapper>
            <Button
              to="/map"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
            >
              Go to Map {hover ? <ArrowForward /> : <ArrowRight />}
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
