import React, { Component, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import emailjs from "emailjs-com";

const config = require("../config.json");
toast.configure();

export default class SensorHealth extends Component {
  state = {
    parkStatus: [],
    courtStatus: [],
    parkDetails: [],
    courtDetails: [],
    sensorDetails: [],
    selectedCourtType: [],
    parkOptions: [],
    courtOptions: [],
    sensorOptions: [],
    selectedPark: [],
    selectedCourt: [],
    selectedSensor: [],
  };

  fetchParkInfo = async () => {
    try {
      const resStatus = await axios.get(
        `${config.api.invokeUrlStatus}/park-sensor-status`
      );

      let parkStatus = resStatus.data;

      //filter to table for just park details
      //essentially a table of unique parks
      let parkDetails = parkStatus.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.park_id === thing.park_id)
      );

      //list all unique parks in park status
      let parkSearch = [...new Set(parkStatus.map((park) => park.park_id))];

      let parkOptions = parkSearch.map((d) => ({
        value: d,
        label: d,
      }));

      // Add an option to the end of our park list
      //parkOptions.push({ value: "New Park", label: "[ Add New Park ]" });

      this.setState({
        parkStatus,
        parkOptions,
        parkDetails,
      });

      console.log("park status:", parkStatus);
    } catch (err) {
      console.log(`An error has occured: ${err}`);
    }
  };

  /* handleChange = (event) => {
    this.setState({ value: event.target.value });
  }; */

  /* onSelectedPark = (event) => {
    //if user selected New Park then set isNewPark to true
    let isNewPark = event.value === "New Park" ? true : false;

    //filter our table to the park we selected above
    let courtStatus = this.state.parkStatus.filter(
      (park) => park.park_id === event.value
    );

    //filter to table for just court details
    //essentially a table of unique courts under our selected park
    let courtDetails = courtStatus.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.court_id === thing.court_id)
    );

    //pull the selected park's address, lat and lng
    let currentPark = courtStatus.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.park_id === thing.park_id)
    );

    //if we are selecting an existing park, set the other fields to this park's details
    if (isNewPark === false) {
      this.setState({
        newPark: {
          ...this.state.newPark,
          park_id: currentPark[0].park_id,
          park_address: currentPark[0].park_address,
          lat: currentPark[0].lat,
          lng: currentPark[0].lng,
        },
      });
    } else {
      //if the Park is new, then reset the
      this.setState({
        newPark: {
          device_id: "",
          park_id: "",
          park_address: "",
          lat: "",
          lng: "",
          court_id: "",
          sensor_name: "",
          Mon: 0,
          Tues: 0,
          Wed: 0,
          Thurs: 0,
          Fri: 0,
          Sat: 0,
          Sun: 0,
          most_recent_activity: "2021-01-01 11:11:11",
          uuid: "",
          sensor_use: "",
        },
      });
    }

    //filter to only unique courts under that selected park
    let courtSearch = [...new Set(courtStatus.map((court) => court.court_id))];

    let courtOptions = courtSearch.map((d) => ({
      value: d,
      label: d,
    }));

    // Add an new option to the end of our court list
    //courtOptions.push({ value: "New Court", label: "[ Add New Court ]" });

    this.setState({
      selectedPark: event,
      isNewPark,
      isCourtDisabled: false,
      isParkSelected: true,
      courtOptions,
      courtStatus,
      courtDetails,
      selectedCourt: "",
      isCourtSelected: false,
    });
  }; */

  /*   customTheme = (theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "orange",
        primary: "grey",
      },
    };
  }; */

  /*   sendEmail = (templateParams) => {
    emailjs
      .send(
        "CheckMyPark",
        "CheckMyPark_template",
        templateParams,
        "5b0rh82UclNMmvlyK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }; */

  batteryHealth = (voltage) => {
    if (voltage > 2) {
      return <p className="tag is-success is-medium">{voltage}</p>;
    } else if (voltage < 2 && voltage > 1) {
      return <p className="tag is-warning is-medium">{voltage}</p>;
    } else if (voltage < 1) {
      return <p className="tag is-danger is-medium">{voltage}</p>;
    }
  };

  // Showcase the time since the last supervisory message
  timeSinceLastSup = (supervisory_date) => {
    //this is converting our UTC supervisory message timestamp to EDT automatically
    //EST is 5 hours behind UTC (UTC-5), EDT is 4 hours behind UTC
    //careful with timestamp_est, I shouldn't be using the rule of -5 because it changes to -4 at some other point in the year.
    var most_recent = new Date(supervisory_date);

    //var device_id = JSON.stringify(device_id);
    //var park_id = JSON.stringify(park_id);

    //console.log("most recent sup", most_recent);

    //logic to convert the supervisory event timestamp to a readable format
    /*     let most_recent_month =
      most_recent.getMonth() +
      1 +
      "-" +
      most_recent.getDate() +
      "-" +
      most_recent.getFullYear() +
      " "; */

    //console.log("latest month", most_recent_month);

    /*     var hours = most_recent.getHours();
    var minutes = most_recent.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm; */

    //console.log("most recet sup", most_recent_sup);

    let today = Date.now();
    today = new Date(today);

    //console.log("today", today);

    //run logic to flag late supervisory messages
    let diffInMilliSeconds = Math.abs(today - most_recent) / 1000;

    // calculate days
    const daysBetween = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= daysBetween * 86400;
    //console.log("calculated days", daysBetween);

    //this is only looking at hours within a single day - not across days...

    // bring in the device and park name that hasn't reported in 2 days
    /*     var templateParams = {
      device_id: device_id,
      park_id: park_id,
    }; */

    if (daysBetween > 1) {
      //send email with specific disconnected device
      //this.sendEmail(templateParams);
      return <p className="tag is-danger is-medium">{daysBetween}</p>;
    } else if (isNaN(daysBetween) === true) {
      return <p className="tag is-danger is-medium">{daysBetween}</p>;
    } else {
      return <p className="tag is-success is-medium">{daysBetween}</p>;
    }
  };

  componentWillMount() {
    this.fetchParkInfo();
  }

  render() {
    //console.log(this.state.newPark);
    //console.log(this.state.parkStatus);
    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <h1>CheckMyCourt Sensor Health</h1>
            <p className="subtitle is-5">
              Check the recent status of your sensors in the field{" "}
            </p>
            <br />
            <p className="subtitle is-5">Current Sensors </p>
            <div className="table-container">
              <table className="table is-striped">
                <thead>
                  <tr>
                    <th>Last Status Msg (Days)</th>
                    <th>Battery Voltage</th>
                    <th>Sensor Name</th>
                    <th>Court Name</th>
                    <th>Park Name</th>
                    <th>Park City</th>
                    <th>Sensor ID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.parkStatus.map((device) => (
                    <tr key={device.device_id}>
                      <td style={{ textAlign: "center" }}>
                        {this.timeSinceLastSup(device.most_recent_supervisory)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {this.batteryHealth(device.device_battery_voltage)}
                      </td>
                      <td>{device.sensor_name}</td>
                      <td>{device.court_id}</td>
                      <td>{device.park_id}</td>
                      <td>{device.park_city}</td>
                      <th>{device.device_id}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
