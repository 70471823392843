import React, { Component, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";

const config = require("../config.json");
toast.configure();

export default class AdminCRUD extends Component {
  state = {
    sensorStatus2: [],
    sensorStatus1: [],
    currentTime: "",
  };

  trim_message = (message) => {
    //split message into pieces and use the event details that are relevant to motion
    let final = message.split(",")[1].split(":")[1];
    var formattedMessage = final;

    return formattedMessage;
  };
  unix_convert = (timestamp) => {
    let unix_timestamp = timestamp;

    var d = new Date(unix_timestamp);

    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();

    var date_format = d.toLocaleDateString("en-US");
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    var formattedTime =
      date_format + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    return formattedTime;
  };

  onSelectedSensor = async (event) => {
    // API call to pull last 10 events from selected sensor

    // sensor 1: 0080000004066290
    // sensor 2: 008000000406628f

    const devStatus1 = await axios.get(
      `${config.api_device.invokeUrlStatus}/${"0080000004066290"}`
    );

    const devStatus2 = await axios.get(
      `${config.api_device.invokeUrlStatus}/${"008000000406628f"}`
    );

    let sensorStatus1 = devStatus1.data;
    let sensorStatus2 = devStatus2.data;

    // add a parameter to each object with a converted timestamp!
    sensorStatus1.forEach((log) => {
      log["timestamp_convert"] = this.unix_convert(log.timestamp_unix_mqtt);
    });

    sensorStatus2.forEach((log) => {
      log["timestamp_convert"] = this.unix_convert(log.timestamp_unix_mqtt);
    });

    // trim the message to only get movement details
    sensorStatus1.forEach((log) => {
      log["trim_message"] = this.trim_message(log.Message);
    });

    sensorStatus2.forEach((log) => {
      log["trim_message"] = this.trim_message(log.Message);
    });

    console.log("first sensor: ", sensorStatus1);
    console.log("second sensor: ", sensorStatus2);

    this.setState({
      sensorStatus2,
      sensorStatus1,
    });
  };

  currentTime = () => {
    console.log(Date.now());

    var d = new Date(Date.now());

    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();

    var date_format = d.toLocaleDateString("en-US");
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    var formattedTime =
      date_format + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    this.setState({
      currentTime: formattedTime,
    });
  };

  refreshSensor = () => {
    this.onSelectedSensor();
    this.currentTime();
  };

  componentWillMount() {
    this.onSelectedSensor();
    this.currentTime();
  }

  render() {
    //console.log(this.state.newPark);

    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>{this.state.currentTime}</div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="button is-info is-medium"
                onClick={this.refreshSensor}
                disabled={this.state.refreshButton}
              >
                Refresh
              </button>
            </div>
            <br />

            <p
              className="subtitle is-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Side - 0080000004066290
            </p>
            <div className="table-container">
              <table className="table is-striped is-narrow">
                <thead>
                  <tr>
                    <th>Timestamp</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sensorStatus1.map((sensor) => (
                    <tr key={sensor.timestamp}>
                      <td>{sensor.timestamp_convert}</td>
                      <td>{sensor.trim_message}</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              className="subtitle is-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Center - 008000000406628f
            </p>
            <div className="table-container">
              <table className="table is-striped">
                <thead>
                  <tr>
                    <th>Timestamp</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sensorStatus2.map((sensor) => (
                    <tr key={sensor.timestamp}>
                      <td>{sensor.timestamp_convert}</td>
                      <td>{sensor.trim_message}</td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
