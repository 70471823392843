import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
/* import AdminCRUD from "./components/AdminCRUD"; */
import SensorHealth from "./components/SensorHealth";
import DeploymentPage from "./components/DeploymentPage";
import DeviceHistory from "./components/DeviceRecentHistory";
import FinalExperiment from "./components/FinalExperiment";

import ReactGa from "react-ga";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import HomeContent from "./components/HomeContent";
import GoogMap from "./components/GoogMap";

library.add(faEdit);

// GOOGLE ANALYTICS
ReactGa.initialize("UA-191260112-1");
// to report which page the user is on given our routers below
ReactGa.pageview(window.location.pathname + window.location.search);

//render method returns markup - JSX which is javascript XML, looks familiar to HTML
//babel takes JSX syntax and translates it to something a browser will understand
class App extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Router>
          <Switch>
            <Route exact path="/" component={HomeContent} />
            <Route exact path="/map" component={GoogMap} />
            <Route exact path="/about" component={HomeContent} />
            <Route exact path="/sensors" component={SensorHealth} />
            <Route exact path="/deployment" component={DeploymentPage} />
            <Route exact path="/devices" component={DeviceHistory} />
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
