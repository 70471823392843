import React from "react";
import news1 from "../AboutPics/cbs_raleigh_2.png";
import news2 from "../AboutPics/raleigh_icon.png";

//https://www.flaticon.com/search?word=basketball&type=icon

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
} from "./InTheNewsElements";

// can grab some good stock images from:
// https://undraw.co/illustrations
//

const InTheNews = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>In the News</ServicesH1>
      <ServicesWrapper id="wrapper">
        <ServicesCard
          id="card1"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              "https://www.cbs17.com/news/local-news/wake-county-news/new-technology-helps-you-know-when-the-courts-are-open-at-raleigh-parks/",
              "_blank"
            );
          }}
        >
          <ServicesIcon src={news1} />
          <ServicesH2>
            New technology helps you know when the courts are open at Raleigh
            parks
          </ServicesH2>
        </ServicesCard>
        <ServicesCard
          id="card2"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              "https://raleighnc.gov/parks/help-test-new-raleigh-smart-city-initiative-check-availability-park-amenities-0",
              "_blank"
            );
          }}
        >
          <ServicesIcon src={news2} />
          <ServicesH2>
            Help Test A New Raleigh Smart City Initiative to Check Availability
            of Park Amenities
          </ServicesH2>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default InTheNews;
