import React from "react";
import styled from "styled-components";

const InputWrapper = styled.label`
  position: relative;
  display: inline-block;
`;

// hide the checkbox by moving it off screen, but keep checkbox functionality there
const Input = styled.input`
  position: absolute;
  left: -9999px;
  top: -9999px;
  &:checked + span {
    background-color: #bfbfbf;
    &:before {
      right: 27px;
    }
  }
  &:focus + span {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &:focus:checked + span {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const Slider = styled.span`
  display: flex;
  cursor: pointer;
  width: 50px;
  height: 25px;
  border-radius: 100px;
  background-color: #1890ff;
  position: relative;
  transition: background-color 0.2s;
  &:before {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    width: 21px;
    height: 21px;
    border-radius: 21px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 2px 4px;
  }
`;

const Toggle = ({ onChange }) => (
  <InputWrapper>
    <Input type="checkbox" onChange={onChange} />
    <Slider />
  </InputWrapper>
);

export default Toggle;
