import React, { Component } from "react";
import CourtSensor from "./CourtSensor";

//attributes set here are passed to our children in 'props' when we call them in our render
export default class Court extends Component {
  state = {
    parkStatus: this.props.parkStatus,
  };

  fetchCourts = async () => {
    try {
      //pre-filtered to the court from parent (Park)
      let parkStatus = this.props.parkStatus.filter(
        (court) => court.court_id === this.props.court_id
      );
      //set state to our filtered data
      this.setState({
        parkStatus,
      });
    } catch (err) {
      console.log(`An error has occured: ${err}`);
    }
  };

  //UI iterates over the array and creates a block for each one!
  componentDidMount = () => {
    this.fetchCourts();
  };
  render() {
    return (
      <div>
        {this.state.parkStatus.map((sensor) => (
          <div
            key={sensor.uuid}
            className="subtitle tile is-parent box is-vertical"
          >
            <CourtSensor
              device_id={sensor.device_id}
              parkStatus={this.state.parkStatus}
              latestSensorStatus={this.props.latestSensorStatus}
              sensor_name={sensor.sensor_name}
              modalState={this.props.modalState}
            />
          </div>
        ))}
      </div>
    );
  }
}
