import React from "react";
import Lottie from "react-lottie";
import * as loading from "../ball-loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loading = () => {
  return (
    <div style={{ marginTop: "10rem" }}>
      <Lottie options={defaultOptions} height={200} width={200} />
      <p className="has-text-centered">Loading...</p>
    </div>
  );
};

export default Loading;
