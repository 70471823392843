export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Plan for Play",
  headline:
    "See which assets are available at local parks before you get there",
  description:
    "Avoid hopping from park to park to find an open tennis court, basketball court or playground.",
  buttonLabel: "Go to Map",
  imgStart: false,
  img: require("../AboutPics/undraw-bball.svg"),
  alt: "Basketball",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "Info Section",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Plan for Play",
  headline:
    "Check which assets are available at local parks before you get there",
  description:
    "Avoid hopping from park to park to find an open tennis court, basketball court or playground.",
  buttonLabel: "Go to Map",
  imgStart: true,
  img: require("../AboutPics/undraw-tennis.svg"),
  alt: "Tennis",
  dark: true,
  primary: false,
  darkText: false,
};

export const homeObjThree = {
  id: "signup",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Join our Team",
  headline: "Creating an account is extremely easy",
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Start Now",
  imgStart: false,
  img: require("../AboutPics/about-cloud.png"),
  alt: "Papers",
  dark: false,
  primary: false,
  darkText: true,
};
