import React, { Component } from "react";
import Court from "./Court";

//attributes set here are passed to our children in 'props' when we call them in our render
export default class Park extends Component {
  state = {
    parkStatus: this.props.latestSensorStatus,
    courts: [],
    isCourtOpen: false,
    countAv: null,
    countAc: null,
    parkAddressText: null,
    parkLink: null,
  };

  fetchParks = () => {
    try {
      //pre-filtered to the park from parent (Park Container)
      let parkStatus = this.props.parkStatus.filter(
        (park) => park.park_id === this.props.park_id
      );

      let parkAddress = parkStatus.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.park_id === thing.park_id)
      );

      const parkAddressText = parkAddress[0].park_address;
      //const parkLat = parkAddress[0].lat;
      //const parkLng = parkAddress[0].lng;
      let parkLink = null;

      //test if user has iphone or not - if so then open in apple maps, if not then open in google maps
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        //parkLink = "http://maps.apple.com/?daddr=" + parkLat + "," + parkLng;
        parkLink = "http://maps.apple.com/?daddr=" + parkAddressText;
      } else {
        //parkLink = "https://maps.google.com/?daddr=" + parkLat + "," + parkLng;
        parkLink = "https://maps.google.com/?daddr=" + parkAddressText;
      }

      let courts = [...new Set(parkStatus.map((court) => court.court_id))];

      //filter our status count from parent component to the current park
      let parkStatusCount = this.props.parkStatusCount.filter(
        (park) => park.park_id === this.props.park_id
      );

      let countAv = parkStatusCount[0].totAv;
      let countAc = parkStatusCount[0].totAc;

      //set our state to our filtered data
      this.setState({
        parkStatus,
        courts,
        countAv,
        countAc,
        parkAddressText,
        parkLink,
      });
    } catch (err) {
      console.log(`An error has occured: ${err}`);
    }
  };

  handleCourtClick = () => {
    this.setState({
      isCourtOpen: !this.state.isCourtOpen,
    });
  };

  //watch out for data from fetchParks not being available for sensorRollUp
  componentDidMount = () => {
    this.fetchParks();
  };

  render() {
    // make the address an href so it's clickable and take to the user to a google URL that has the coordinates or address you provided

    return (
      <section>
        <div>
          <div
            className="modal-header level is-mobile"
            style={{ marginBottom: 0 + "em" }}
          >
            {/*             <p className="level-item subtitle is-size-6-mobile has-text-weight-bold">
              {this.props.park_id}
            </p> */}
            <a
              className="level-item button is-link is-outlined"
              href={this.state.parkLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Directions
            </a>
            <p className="level-item tag is-success is-medium">
              {this.state.countAv}
            </p>
            <p className="level-item tag is-danger is-medium">
              {this.state.countAc}
            </p>
          </div>
          <br />
          <div>
            {this.state.courts.map((court) => (
              <div key={court} className="tile is-ancestor box is-vertical">
                <p className="subtitle is-size-6-mobile has-text-weight-bold">
                  {court}
                </p>
                <Court
                  parkStatus={this.state.parkStatus}
                  latestSensorStatus={this.props.latestSensorStatus}
                  court_id={court}
                  modalState={this.props.modalState}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}
