import React, { Component } from "react";

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="CheckMyPark_Logo.jpg" alt="Check My Court logo" />
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <a href="/map" className="navbar-item">
              Map
            </a>
            <a href="/" className="navbar-item">
              About
            </a>
          </div>
          <div className="navbar-end"></div>
        </div>
      </nav>
    );
  }
}
