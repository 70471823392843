import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io";
import Toggle from "./Toggle";
import { IconContext } from "react-icons/lib";

const Nav = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-right: 1rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  background: #3498db;
  width: 100%;
`;

const SidebarLink = styled.div`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
`;

const SidebarLinkFeedback = styled.div`
  background: #3498db;
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  border-bottom: 1px solid #1274bf;
  align-items: center;
  padding: 10px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background: #00d1b2;
    transition: 0.2s ease-in-out;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

//add a container around the Toggle so we can right align it
const ToggleContainer = styled.div`
  margin-left: auto;
  margin-right: 10px;
`;

const DropdownLink = styled.div`
  background: #1274bf;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
`;

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.filterBball = this.filterBball.bind(this);
    this.filterTennis = this.filterTennis.bind(this);
    this.filterPlayground = this.filterPlayground.bind(this);
    this.sideBarToggle = this.sideBarToggle.bind(this);
  }

  sideBarToggle = () => {
    this.props.onSideBarToggle();
  };

  filterBball = (event) => {
    //console.log("basketball:", event);
    this.props.onBballFilter(event);
  };

  filterTennis = (event) => {
    //console.log("tennis:", event);
    this.props.onTennisFilter(event);
  };

  filterPlayground = (event) => {
    //console.log("playground:", event);
    this.props.onPlaygroundFilter(event);
  };

  reportProblem = () => {
    window.open("https://forms.gle/kDTchXQG4rFLvtvm7", "_blank");
  };

  submitFeedback = () => {
    window.open("https://forms.gle/gB1fEpBmXv7EcTcw6", "_blank");
  };

  render() {
    return (
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav id="nav">
          <NavIcon to="#">
            {/*           IoIcons.IoMdOptions*/}
            <GiIcons.GiHamburgerMenu onClick={this.sideBarToggle} />
          </NavIcon>
        </Nav>
        <SidebarNav sidebar={this.props.sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={this.sideBarToggle} />
            </NavIcon>
            <>
              <SidebarLink>
                <div>
                  <IoIcons.IoMdOptions />
                  <SidebarLabel>Filter an Activity</SidebarLabel>
                </div>
              </SidebarLink>
              <DropdownLink>
                <GiIcons.GiBasketballBall />
                <SidebarLabel>Basketball</SidebarLabel>
                <ToggleContainer>
                  <Toggle
                    onChange={(event) => this.filterBball(event.target.checked)}
                  />
                </ToggleContainer>
              </DropdownLink>

              <DropdownLink>
                <GiIcons.GiTennisBall />
                <SidebarLabel>Tennis</SidebarLabel>
                <ToggleContainer>
                  <Toggle
                    onChange={(event) =>
                      this.filterTennis(event.target.checked)
                    }
                  />
                </ToggleContainer>
              </DropdownLink>

              <DropdownLink>
                <FaIcons.FaChild />
                <SidebarLabel>Playground</SidebarLabel>
                <ToggleContainer>
                  <Toggle
                    onChange={(event) =>
                      this.filterPlayground(event.target.checked)
                    }
                  />
                </ToggleContainer>
              </DropdownLink>
              <SidebarLinkFeedback onClick={this.submitFeedback}>
                <div>
                  <MdIcons.MdFeedback />
                  <SidebarLabel>Submit Feedback</SidebarLabel>
                </div>
              </SidebarLinkFeedback>
              <SidebarLinkFeedback onClick={this.reportProblem}>
                <div>
                  <MdIcons.MdHelp />
                  <SidebarLabel>Report a Problem</SidebarLabel>
                </div>
              </SidebarLinkFeedback>
            </>
            ;
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    );
  }
}
