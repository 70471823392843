import React, { Component } from "react";
import "../App.css";
import axios from "axios";
import Park from "./Park";
import Loading from "./Loading";
import Select from "react-select";
import ReactGa from "react-ga";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import Sidebar from "./Sidebar";
import styled from "styled-components";

const config = require("../config.json");

export default class GoogMap extends Component {
  state = {
    parkStatus: [],
    options: [],
    selectedOption: [],
    latestSensorStatus: [],
    parkStatusCount: [],
    parkSearch: [],
    park: null,
    park_label: null,
    marked_type: null,
    done: false,
    modalState: "modal",
    feedbackModalState: "modal",
    sideBarOpen: false,
    filtered_activities: [],
    //cityModalState: "modal is-active",
    citySelected: "Raleigh",
  };

  fetchCourts = async () => {
    try {
      this.setState({
        done: false,
      });

      const resStatus = await axios.get(
        `${config.api.invokeUrlStatus}/park-sensor-status`
      );

      let parkStatus = resStatus.data;

      /// sort incoming by park_id, court_id then sensor_name (below logic for comparing strings)

      try {
        parkStatus.sort(
          (a, b) =>
            b.park_city.localeCompare(a.park_city) ||
            a.park_id.localeCompare(b.park_id) ||
            a.court_id.localeCompare(b.court_id) ||
            a.sensor_name.localeCompare(b.sensor_name)
        );
      } catch (err) {
        console.log(`An error has occured: ${err}`);
      }

      //set park labels to the distinct parks from our API
      let park_labels = [...new Set(parkStatus.map((park) => park.park_label))];
      let park_label_search = park_labels;

      //set dropdown select options to our park labels
      let options = park_label_search.map((d) => ({
        value: d,
        label: d,
      }));

      // Add an option to the end of our park list
      //options.push({ value: "New Park Value", label: "[ Add New Park ]" });

      let parkStatusCount = [];
      let latestSensorStatus = [];

      //create our object of sensor status by park_ID. use forEach instead of map function, since we don't need to return any values
      //https://stackoverflow.com/questions/48163744/expected-to-return-a-value-in-arrow-function-array-callback-return-why
      parkStatus.forEach((myPark) => {
        let courtStatus = null;
        let today = Date.now();
        today = new Date(today);

        //time comes from sensor in UTC - to Date will convert it to local browser time
        var latestDate = new Date(myPark.most_recent_activity);

        //run logic to find if sensor is active
        const diffMs = today.getTime() - latestDate.getTime();
        const diffDays = Math.floor(diffMs / 86400000); // days
        const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // mins
        const diffTotalMins = diffDays * 1440 + diffHrs * 60 + diffMins;

        const timeSinceLast =
          diffDays.toString() +
          " days " +
          diffHrs.toString() +
          " hours " +
          diffMins.toString() +
          " mins";

        if (diffTotalMins > 15) {
          courtStatus = "AVAILABLE";
        } else {
          courtStatus = "ACTIVE";
        }

        //build a new object attaching the status of the sensor to our incoming data
        let sensorStatusRollUp = {};
        sensorStatusRollUp["park_id"] = myPark.park_id;
        sensorStatusRollUp["sensor"] = myPark.device_id;
        sensorStatusRollUp["most_recent_activity"] =
          myPark.most_recent_activity;
        sensorStatusRollUp["time_since_last"] = timeSinceLast;
        sensorStatusRollUp["status"] = courtStatus;

        //push that object to our array
        latestSensorStatus.push(sensorStatusRollUp);
      });

      //split out arrays into park object to capture sensor counts within each park
      var parkObj = {};
      var parkLength = latestSensorStatus.length;
      for (var i = 0; i < parkLength; i++) {
        var p = latestSensorStatus[i]["park_id"];
        if (!parkObj[p]) {
          parkObj[p] = [];
        }
        parkObj[p].push(latestSensorStatus[i]);
      }

      for (var key in parkObj) {
        //
        let objLength = parkObj[key].length;
        let totAv = 0;
        let totAc = 0;

        for (var a = 0; a < objLength; a++) {
          if (parkObj[key][a].status === "AVAILABLE") totAv++;
          if (parkObj[key][a].status === "ACTIVE") totAc++;
        }

        let sensorSumPark = {};
        sensorSumPark["park_id"] = key;
        sensorSumPark["totAv"] = totAv;
        sensorSumPark["totAc"] = totAc;
        sensorSumPark["total"] = totAv + totAc;
        sensorSumPark["perc_av"] = totAv / (totAv + totAc);

        parkStatusCount.push(sensorSumPark);
      }

      this.setState(
        {
          parkStatus,
          options,
          latestSensorStatus,
          parkStatusCount,
          done: true,
        },
        this.renderMap()
      );
    } catch (err) {
      console.log(`An error has occured: ${err}`);
    }
  };

  renderMap = () => {
    loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD2aCG8JNp-SEI7QCKdw94UhryX3T64DiM&callback=initMap"
    );
    window.initMap = this.initMap;
  };

  /* /// Button on map to center on the user
  CenterControl(controlDiv, map) {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "8px";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click to recenter the map on my location";
    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior.
    const controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "12px";
    controlText.style.lineHeight = "30px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Find My Location";
    controlUI.appendChild(controlText);
    // Setup the click event listeners: simply set the map to users location.
    controlUI.addEventListener("click", () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            map.setCenter(pos);
            map.setZoom(13);
          },
          //error handling if the user elects not to permit location services
          () => {
            handleLocationError(true, map.getCenter());
          }
        );
      } else {
        // if the user denies the location consent, send them to our default city center
        handleLocationError(false, map.getCenter());
      }

      // function for the handler if the user denies location access
      function handleLocationError(browserHasGeolocation, pos) {
        alert(
          "Enable location services on the browser to recenter the map to your location"
        );
        map.setCenter({ lat: 42.3518, lng: -71.0674 }); //boston coordinates
        map.setZoom(13);
      }
    });
  }
 */

  centerBoston = () => {
    //filter our raw data to only Boston
    let BostonParkData = this.state.parkStatus.filter(
      (park) => park.park_city === "Boston"
    );

    // filter the raw data to only the park names in Boston
    let parksBoston = [...new Set(BostonParkData.map((park) => park.park_id))];

    // set our options to these Boston parks
    let optionsBoston = parksBoston.map((d) => ({
      value: d,
      label: d,
    }));

    this.setState({
      citySelected: "Boston",
      options: optionsBoston,
      // done: true,
    });
    this.renderMap();
    this.closeCityModal();
  };

  centerRaleigh = () => {
    //filter our raw data to only Raleigh
    let RaleighParkData = this.state.parkStatus.filter(
      (park) => park.park_city === "Raleigh"
    );

    // filter the raw data to only the park names in Raleigh
    let parksRaleigh = [
      ...new Set(RaleighParkData.map((park) => park.park_id)),
    ];

    // set our options to these Raleigh parks
    let optionsRaleigh = parksRaleigh.map((d) => ({
      value: d,
      label: d,
    }));

    this.setState({
      citySelected: "Raleigh",
      options: optionsRaleigh,
    });
    this.renderMap();
    this.closeCityModal();
  };

  //set up a report a problem button that floats on the map
  ReportProblem = (controlDiv, map) => {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");

    controlUI.style.backgroundColor = "#3498db"; //same blue as above
    //controlUI.style.backgroundColor = "blue";
    controlUI.style.border = "2px solid #3498db";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "8px";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Report a Problem";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");

    //controlText.style.color = "rgb(25,25,25)";
    controlText.style.color = "white";
    controlText.style.fontWeight = "bold";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "12px";
    controlText.style.lineHeight = "22px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Report a Problem";
    controlUI.appendChild(controlText);
    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      this.setState({
        feedbackModalState: "modal is-active",
      });
      //window.open("https://forms.gle/kDTchXQG4rFLvtvm7", "_blank");
    });
  };

  //set up a report a problem button that floats on the map
  IconReportProblem = (controlDiv, map) => {
    const controlUI = document.createElement("div");

    controlUI.style.marginTop = "8px";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";
    controlDiv.appendChild(controlUI);

    const controlImg = document.createElement("img");

    controlImg.src = "feedback_icon.png";
    controlImg.style.width = "80%";
    //https://github.com/icons8/flat-color-icons/blob/master/svg/about.svg

    controlUI.appendChild(controlImg);

    /*    // Set CSS for the control interior.
    const controlText = document.createElement("div");

    //controlText.style.color = "rgb(25,25,25)";
    controlText.style.color = "white";
    controlText.style.fontWeight = "bold";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "12px";
    controlText.style.lineHeight = "22px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Report a Problem";
    controlUI.appendChild(controlText); */

    controlUI.addEventListener("click", () => {
      this.setState({
        feedbackModalState: "modal is-active",
      });
      //window.open("https://forms.gle/kDTchXQG4rFLvtvm7", "_blank");
    });
  };

  ReportProblemBtn = () => {
    window.open("https://forms.gle/kDTchXQG4rFLvtvm7", "_blank");
  };

  SendFeedbackBtn = () => {
    window.open("https://forms.gle/gB1fEpBmXv7EcTcw6", "_blank");
  };

  // passing up the state of our filters from the sidebar component
  // this should inform which markers to load when initiating the map
  // https://reactjs.org/docs/lifting-state-up.html
  handleBballFilter = (event) => {
    //if event is true, then add bball to filtered_activites array
    //if event is false, then remove bball from the filtered_activities array
    let updated_filter = [...this.state.filtered_activities];

    if (event === true) {
      updated_filter.push("Basketball");
    } else if (event === false) {
      updated_filter = updated_filter.filter(
        (activity) => activity !== "Basketball"
      );
    }

    //console.log("filtered list from func: ", updated_filter);

    this.setState(
      {
        filtered_activities: updated_filter,
      },
      //set the state and pass it into the initMap function right away
      () => this.initMap()
    );
  };

  handleTennisFilter = (event) => {
    let updated_filter = [...this.state.filtered_activities];

    if (event === true) {
      updated_filter.push("Tennis");
    } else if (event === false) {
      updated_filter = updated_filter.filter(
        (activity) => activity !== "Tennis"
      );
    }

    //console.log("filtered list from func: ", updated_filter);

    this.setState(
      {
        filtered_activities: updated_filter,
      },
      //set the state and pass it into the initMap function right away
      () => this.initMap()
    );
  };

  handlePlaygroundFilter = (event) => {
    let updated_filter = [...this.state.filtered_activities];

    if (event === true) {
      updated_filter.push("Playground");
    } else if (event === false) {
      updated_filter = updated_filter.filter(
        (activity) => activity !== "Playground"
      );
    }

    //console.log("filtered list from func: ", updated_filter);

    this.setState(
      {
        filtered_activities: updated_filter,
      },
      //set the state and pass it into the initMap function right away
      () => this.initMap()
    );
  };

  initMap = (selectedOption) => {
    //console.log("filtered items from map: ", this.state.filtered_activities);
    // Create a  map
    var map = new window.google.maps.Map(document.getElementById("map"), {
      disableDefaultUI: true, //this will strip the default controls off the the google map
      // zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: false,
      clickableIcons: false,
      options: {
        gestureHandling: "greedy",
      },
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
      //center: { lat: 35.807322, lng: -78.729738 },
      // zoom: 14,
    });

    // Create the DIV to hold the control and call the button function
    // constructor passing in this DIV.
    const centerControlDiv = document.createElement("div");

    // feedback button
    //const leftControlDiv = document.createElement("div");

    this.ReportProblem(centerControlDiv, map);
    map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
      centerControlDiv
    );

    // initiate the center on the user
    /*     const centerControlDiv = document.createElement("div");
    centerControlDiv.style.marginLeft = "5px";
    this.CenterControl(centerControlDiv, map);
    map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
      centerControlDiv
    ); */

    let selectedCenter = [];

    //if we have something selected from the dropdown, filter to the selected value to pull lat/lng
    if (selectedOption !== undefined) {
      selectedCenter = this.state.parkStatus.filter(
        (park) => park.park_label === selectedOption["value"]
      );

      //remove duplicates to get single selected option with lat/lng
      selectedCenter = selectedCenter.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.park_address === thing.park_address)
      );
    }

    let selectedLat = null;
    let selectedLng = null;

    // UNLOCK THIS AGAIN WHEN READY TO SELECT CITY

    //if an option has been selected from the dropdown, then set the center to the current selection and set the zoom. else center on the city picked initally
    if (selectedCenter.length > 0) {
      selectedLat = selectedCenter[0].lat;
      selectedLng = selectedCenter[0].lng;

      map.setCenter({ lat: selectedLat, lng: selectedLng });
      map.setZoom(16);
    }
    //depending on the state variable of the city the user selects, set the map center
    else if (this.state.citySelected === "Boston") {
      map.setCenter({ lat: 42.3518, lng: -71.0674 }); //boston coordinates
      map.setZoom(13);
    } else if (this.state.citySelected === "Raleigh") {
      map.setCenter({ lat: 35.7765, lng: -78.6498 }); //Raleigh coordinates
      map.setZoom(12);
    }

    // Use an ELSE IF to check the user location if they've consented to it
    // https://developers.google.com/maps/documentation/javascript/geolocation#maps_map_geolocation-javascript
    /*    else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(pos);
          map.setZoom(13);
        },
        () => {
          handleLocationError(true, map.getCenter());
        }
      );
    } else {
      // if the user denies the location consent, send them to our default city center
      handleLocationError(false, map.getCenter());
    }

    // function for the handler if the user denies location access
    function handleLocationError(browserHasGeolocation, pos) {
      map.setCenter({ lat: 42.3518, lng: -71.0674 }); //boston coordinates
      map.setZoom(13);
    } */

    // var modal = document.getElementById("myModal");
    //modal.style.display = "none";

    //create an infowindow
    //var infowindow = new window.google.maps.InfoWindow();

    //drop in some icons and reference them here
    //const image = "favicon.ico";

    //https://sites.google.com/site/gmapsdevelopment/
    // use this site for current map icons
    //https://mapicons.mapsmarker.com/markers/sports/ball-sports/basketball/?custom_color=ff6b26
    //green: 57e341
    //yellow: FADE28
    //red: DE5A2F
    //const greene = "http://maps.google.com/mapfiles/ms/icons/green-dot.png"; //small green
    //const greener = "http://maps.google.com/mapfiles/kml/paddle/grn-circle.png"; //large green
    const bballgreen = "basketball_ball-green.png";
    const bballyellow = "basketball_ball-yellow.png";
    const bballred = "basketball_ball-red.png";

    const tennisgreen = "tennis-green.png";
    const tennisyellow = "tennis-yellow.png";
    const tennisred = "tennis-red.png";

    const pggreen = "pg-green.png";
    const pgyellow = "pg-yellow.png";
    const pgred = "pg-red.png";

    let icon_image = null;

    //dynamically loop over our courts and lat/lng data to put court markers on the map
    // use forEach instead of map function given that we don't need to return any values
    // https://stackoverflow.com/questions/48163744/expected-to-return-a-value-in-arrow-function-array-callback-return-why
    this.state.parkStatus.forEach((myCourt) => {
      var contentString = `${myCourt.park_id}`;

      let parkStatus = this.state.parkStatusCount.filter(
        (park) => park.park_id === myCourt.park_id
      );

      //return the perc_av from the first item in parkStatus, which should be a single line since we filter it above
      // console.log(parkStatus[0].perc_av);

      //conditional coloring of the current marker
      //use index of 0 because we have it filtered to single row
      if (myCourt.sensor_use === "Basketball") {
        if (parkStatus[0].perc_av === 0) {
          icon_image = bballred;
        } else if (parkStatus[0].perc_av < 1) {
          icon_image = bballyellow;
        } else if (parkStatus[0].perc_av === 1) {
          icon_image = bballgreen;
        }
      } else if (myCourt.sensor_use === "Tennis") {
        if (parkStatus[0].perc_av === 0) {
          icon_image = tennisred;
        } else if (parkStatus[0].perc_av < 1) {
          icon_image = tennisyellow;
        } else if (parkStatus[0].perc_av === 1) {
          icon_image = tennisgreen;
        }
      } else if (myCourt.sensor_use === "Playground") {
        if (parkStatus[0].perc_av === 0) {
          icon_image = pgred;
        } else if (parkStatus[0].perc_av < 1) {
          icon_image = pgyellow;
        } else if (parkStatus[0].perc_av === 1) {
          icon_image = pggreen;
        }
      }

      //creating markers
      var marker = new window.google.maps.Marker({
        position: { lat: myCourt.lat, lng: myCourt.lng },
        map: map,
        icon: icon_image,
        title: myCourt.park_id,
      });

      // check if the sensor_use exists in the "filtered_activities" array given our toggle selection, if it does then set visibility to false!
      if (
        this.state.filtered_activities.includes(myCourt.sensor_use) === true
      ) {
        marker.setVisible(false);
      } else {
        marker.setVisible(true);
      }

      /*    if (
        selectedCenter.length > 0 &&
        (selectedOption["value"] = myCourt.park_id)
      ) {
        //change the content of the window
        infowindow.setContent(contentString);
        //open an info window
        infowindow.open(map, marker);
      } */

      // Launch a modal when the marker is clicked!
      // Have the modal cotents be <Complex />
      // with park_id passed as a prop so that we can render our component after clicking the 'complex' marker
      // click marker and it should show modal with 'park.js'

      //link the Click method to render the window
      marker.addListener("click", () => {
        //display your modal div
        //modal.style.display = "block";

        ReactGa.event({
          category: contentString,
          action: "Park selected on map",
        });

        //push the complexID into something outside this function, then pass it to our park component!
        //this won't work - we have lost 'this'. if we change the add listener to an arrow function, this is resolved!
        //when clicked, limit our park array to only the one we clicked
        let parks = [];
        parks.push(contentString);

        this.setState({
          park: contentString,
          park_label: myCourt.park_label,
          marked_type: myCourt.sensor_use,
          parks: parks,
          modalState: "modal is-active",
        });
      });

      /*       map.addListener("click", () => {
        modal.style.display = "none";
      }); */
    });
  };

  closeModal = () => {
    // var modal = document.getElementById("myModal");
    // modal.style.display = "none";

    //reset the park component to all parks when modal is closed
    this.setState({
      parks: [...new Set(this.state.parkStatus.map((park) => park.park_id))],
      modalState: "modal",
    });
  };

  closeFeedbackModal = () => {
    this.setState({
      feedbackModalState: "modal",
    });
  };

  closeCityModal = () => {
    //reset the park component to all parks when modal is closed
    this.setState({
      cityModalState: "modal",
    });
  };

  handleSidebar = () => {
    this.setState((prevState) => ({
      sideBarOpen: !prevState.sideBarOpen,
    }));
  };

  closeSidebar = () => {
    this.setState({
      sideBarOpen: false,
    });
  };

  customTheme = (theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "orange",
        primary: "grey",
      },
    };
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    //pass your selected option into your map function and re-render the map with the selected option as your centerpoint!
    this.initMap(selectedOption);
  };

  componentDidMount() {
    this.fetchCourts();
    // prevent scrolling on the main window - keeps the user scrolling only within the modal
    document.body.style.overflow = "hidden";
  }

  render() {
    //console.log("state to send to sidebar child: ", this.state.sideBarOpen);

    ///problem - our PARK is rendering before we click our map and set a state value to pass into it....
    // add a key to our Park component so that every time that key changes our component will re-render

    //add a loading component that shows prior to the API returning it's results so user get some feedback
    //if done is false, then show loading, but once it's true return the map!

    //print the state of the child sidebar component - looks to correctly lifting the state of the lower component!
    /*     console.log("bball:", this.state.basketball);
    console.log("tennis:", this.state.tennis);
    console.log("playground:", this.state.playground); */

    const NavIcon = styled.div`
      margin-right: 1rem;
      font-size: 2rem;
      height: max;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    `;

    const FeedbackDiv = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5%;
      margin-right: 10%;
      margin-left: 10%;
    `;

    //set the icon in the modal header depending on the sensor type
    var icon;
    if (this.state.marked_type === "Basketball") {
      icon = <GiIcons.GiBasketballBasket />;
    } else if (this.state.marked_type === "Tennis") {
      icon = <GiIcons.GiTennisRacket />;
    } else if (this.state.marked_type === "Playground") {
      icon = <FaIcons.FaChild />;
    }

    return (
      <section id="page-container">
        {/*         <div className={this.state.cityModalState}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title"> Please Select a City</p>
            </header>
            <section className="modal-card-body level is-mobile">
              <button
                className="level-item button is-success is-outlined"
                onClick={this.centerBoston}
              >
                Boston
              </button>
              <button
                className="level-item button is-info is-outlined"
                onClick={this.centerRaleigh}
              >
                Raleigh
              </button>
            </section>
          </div>
        </div> */}

        <div
          className="mapDropdown"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div id="sidebar">
            <Sidebar
              onBballFilter={this.handleBballFilter}
              onTennisFilter={this.handleTennisFilter}
              onPlaygroundFilter={this.handlePlaygroundFilter}
              onSideBarToggle={this.handleSidebar}
              sidebar={this.state.sideBarOpen}
            />
          </div>
          <div style={{ width: "90%" }}>
            <Select
              value={this.state.selectedOption}
              theme={this.customTheme}
              placeholder="Select a nearby park"
              isSearchable
              onChange={this.handleChange}
              options={this.state.options}
            />
          </div>
        </div>

        {!this.state.done ? (
          <Loading />
        ) : (
          <div id="map" onClick={this.closeSidebar}></div>
        )}

        <div className={this.state.feedbackModalState}>
          <div
            className="modal-background"
            onClick={this.closeFeedbackModal}
          ></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Submit Feedback</p>
              <button
                className="delete"
                aria-label="close"
                onClick={this.closeFeedbackModal}
              ></button>
            </header>
            <section className="modal-card-body">
              <FeedbackDiv>
                <button
                  className="button is-info"
                  style={{ width: "100%", fontWeight: "bold" }}
                  onClick={this.ReportProblemBtn}
                >
                  Report a Problem
                </button>
              </FeedbackDiv>
              <FeedbackDiv>
                <button
                  className="button is-primary"
                  style={{ width: "100%", fontWeight: "bold" }}
                  onClick={this.SendFeedbackBtn}
                >
                  Submit Website Feedback
                </button>
              </FeedbackDiv>
            </section>
          </div>
        </div>

        <div className={this.state.modalState}>
          <div className="modal-background" onClick={this.closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <NavIcon>{icon}</NavIcon>
              <p className="modal-card-title"> {this.state.park_label}</p>
              <button
                className="delete"
                aria-label="close"
                onClick={this.closeModal}
              ></button>
            </header>
            <section className="modal-card-body">
              <div key={this.state.park}>
                <Park
                  latestSensorStatus={this.state.latestSensorStatus}
                  parkStatusCount={this.state.parkStatusCount}
                  park_id={this.state.park}
                  marked_type={this.state.marked_type}
                  parkStatus={this.state.parkStatus}
                  modalState={this.state.modalState}
                />
              </div>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

function loadScript(url) {
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}
