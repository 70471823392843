import React, { Component, Fragment } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const config = require("../config.json");
toast.configure();

export default class SensorHealth extends Component {
  state = {
    parkStatus: [],
    courtStatus: [],
    parkDetails: [],
    courtDetails: [],
    sensorDetails: [],
    selectedCourtType: [],
    parkOptions: [],
    courtOptions: [],
    sensorOptions: [],
    selectedPark: [],
    selectedCourt: [],
    selectedSensor: [],
  };

  fetchParkInfo = async () => {
    try {
      const resStatus = await axios.get(
        `${config.api.invokeUrlStatus}/park-sensor-status`
      );

      let parkStatus = resStatus.data;

      /// sort incoming by park_id, court_id then sensor_name (below logic for comparing strings)
      parkStatus.sort(
        (a, b) =>
          b.park_city.localeCompare(a.park_city) ||
          a.park_id.localeCompare(b.park_id) ||
          a.court_id.localeCompare(b.court_id) ||
          a.sensor_name.localeCompare(b.sensor_name)
      );

      this.setState({
        parkStatus,
      });
    } catch (err) {
      console.log(`An error has occured: ${err}`);
    }
  };

  // Showcase the time since the last supervisory message
  timeSinceLastSup = (supervisory_date, recent_activity, sensor_use) => {
    var sup_date = new Date(supervisory_date);
    var recent_date = new Date(recent_activity);

    let today = Date.now();
    today = new Date(today);

    //most recent times (Acceleration)
    let diff_rec = Math.abs(today - recent_date);
    var minutes_rec = Math.floor(diff_rec / 1000 / 60);

    // supervisory times (Motion)
    let diff_sup = Math.abs(today - sup_date);
    var minutes_sup = Math.floor(diff_sup / 1000 / 60);

    //const daysBetween_sup = Math.floor(diffInMilliSeconds_sup / 86400);
    //let diffInMilliSeconds_sup = Math.abs(today - sup_date) / 1000;
    //const MinsBetween_sup = Math.floor(diffInMilliSeconds_sup / 60000);

    if (sensor_use === "Basketball") {
      // if it's an acceleration sensor, use most_recent_timestamp for latest
      if (minutes_rec > 5) {
        return <p className="tag is-danger is-medium">{minutes_rec}</p>;
      } else if (isNaN(minutes_rec) === true) {
        return <p className="tag is-danger is-medium">{minutes_rec}</p>;
      } else {
        return <p className="tag is-success is-medium">{minutes_rec}</p>;
      }
    }
    // if it's a motions sensor, we want to use the supervisory message for latest
    else {
      if (minutes_sup > 10) {
        return <p className="tag is-danger is-medium">{minutes_sup}</p>;
      } else if (isNaN(minutes_sup) === true) {
        return <p className="tag is-danger is-medium">{minutes_sup}</p>;
      } else {
        return <p className="tag is-success is-medium">{minutes_sup}</p>;
      }
    }
  };

  componentWillMount() {
    this.fetchParkInfo();
  }

  render() {
    //console.log(this.state.newPark);
    //console.log(this.state.parkStatus);
    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <h1>CheckMyCourt Deployment Support</h1>
            <p className="subtitle is-5">
              Check the connectivity of your recently deployed sensors{" "}
            </p>
            <br />
            <p className="subtitle is-5">Current Sensors </p>
            <div className="table-container">
              <table className="table is-striped">
                <thead>
                  <tr>
                    <th>Park City</th>
                    <th>Park Name</th>
                    <th>Court Name</th>
                    <th>Sensor Name</th>
                    <th>Sensor ID</th>
                    <th>Last Status Msg (Minutes)</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.parkStatus.map((device) => (
                    <tr key={device.device_id}>
                      <td>{device.park_city}</td>
                      <td>{device.park_id}</td>
                      <td>{device.court_id}</td>
                      <td>{device.sensor_name}</td>
                      <th>{device.device_id}</th>
                      <td style={{ textAlign: "center" }}>
                        {this.timeSinceLastSup(
                          device.most_recent_supervisory,
                          device.most_recent_activity,
                          device.sensor_use
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
