import React from "react";
import AboutInfoSection from "./AboutInfoSection";
import HeroSection from "./HeroSection";
import { homeObjTwo } from "./AboutInfoData";
import AboutHowitWorks from "./AboutHowitWorks";
import InTheNews from "./InTheNews";

export default function HomeContent() {
  return (
    <>
      <div id="AboutContainer">
        <HeroSection />
        <AboutInfoSection {...homeObjTwo} />
        <AboutHowitWorks />
        <InTheNews />
      </div>
    </>
  );
}
