import React, { Component, Fragment } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";

const config = require("../config.json");
toast.configure();

export default class AdminCRUD extends Component {
  state = {
    openAddPark: false,
    openAddCourt: false,
    openAddSensor: false,
    refreshButton: true,
    parkStatus: [],
    courtStatus: [],
    sensorStatus: [],
    confirmModalState: "modal",
    newParkModalState: "modal",
    newCourtModalState: "modal",
    newSensorModalState: "modal",
    newPark: {
      device_id: "",
      park_id: "",
      park_address: "",
      lat: "",
      lng: "",
      court_id: "",
      sensor_name: "",
      Mon: 0,
      Tues: 0,
      Wed: 0,
      Thurs: 0,
      Fri: 0,
      Sat: 0,
      Sun: 0,
      most_recent_activity: "2021-01-01 11:11:11",
      uuid: "",
      sensor_use: "",
    },
    parkDetails: [],
    courtDetails: [],
    sensorDetails: [],
    selectedCourtType: [],
    sensorUseOptions: [
      { value: "Basketball", label: "Basketball" },
      { value: "Tennis", label: "Tennis" },
      { value: "Volleyball", label: "Volleyball" },
    ],
    parkOptions: [],
    courtOptions: [],
    sensorOptions: [],
    selectedPark: [],
    selectedCourt: [],
    selectedSensor: [],
    isNewPark: false,
    isNewCourt: false,
    isNewSensor: false,
    isCourtDisabled: true,
    isSensorDisabled: true,
    isCourtSelected: false,
    isParkSelected: false,
  };

  fetchParkInfo = async () => {
    try {
      const resStatus = await axios.get(
        `${config.api.invokeUrlStatus}/park-sensor-status`
      );

      let parkStatus = resStatus.data;

      parkStatus.sort(
        (a, b) =>
          b.park_city.localeCompare(a.park_city) ||
          a.park_id.localeCompare(b.park_id) ||
          a.court_id.localeCompare(b.court_id) ||
          a.sensor_name.localeCompare(b.sensor_name)
      );

      //filter to table for just park details
      //essentially a table of unique parks
      let parkDetails = parkStatus.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.park_id === thing.park_id)
      );

      //list all unique parks in park status
      let parkSearch = [...new Set(parkStatus.map((park) => park.park_id))];

      let parkOptions = parkSearch.map((d) => ({
        value: d,
        label: d,
      }));

      // Add an option to the end of our park list
      //parkOptions.push({ value: "New Park", label: "[ Add New Park ]" });

      this.setState({
        parkStatus,
        parkOptions,
        parkDetails,
      });
    } catch (err) {
      console.log(`An error has occured: ${err}`);
    }
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  onSelectedPark = (event) => {
    //if user selected New Park then set isNewPark to true
    let isNewPark = event.value === "New Park" ? true : false;

    //filter our table to the park we selected above
    let courtStatus = this.state.parkStatus.filter(
      (park) => park.park_id === event.value
    );

    //filter to table for just court details
    //essentially a table of unique courts under our selected park
    let courtDetails = courtStatus.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.court_id === thing.court_id)
    );

    //pull the selected park's address, lat and lng
    let currentPark = courtStatus.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.park_id === thing.park_id)
    );

    //if we are selecting an existing park, set the other fields to this park's details
    if (isNewPark === false) {
      this.setState({
        newPark: {
          ...this.state.newPark,
          park_id: currentPark[0].park_id,
          park_address: currentPark[0].park_address,
          lat: currentPark[0].lat,
          lng: currentPark[0].lng,
        },
      });
    } else {
      //if the Park is new, then reset the
      this.setState({
        newPark: {
          device_id: "",
          park_id: "",
          park_address: "",
          lat: "",
          lng: "",
          court_id: "",
          sensor_name: "",
          Mon: 0,
          Tues: 0,
          Wed: 0,
          Thurs: 0,
          Fri: 0,
          Sat: 0,
          Sun: 0,
          most_recent_activity: "2021-01-01 11:11:11",
          uuid: "",
          sensor_use: "",
        },
      });
    }

    //filter to only unique courts under that selected park
    let courtSearch = [...new Set(courtStatus.map((court) => court.court_id))];

    let courtOptions = courtSearch.map((d) => ({
      value: d,
      label: d,
    }));

    // Add an new option to the end of our court list
    //courtOptions.push({ value: "New Court", label: "[ Add New Court ]" });

    this.setState({
      selectedPark: event,
      isNewPark,
      isCourtDisabled: false,
      isParkSelected: true,
      courtOptions,
      courtStatus,
      courtDetails,
      selectedCourt: "",
      isCourtSelected: false,
    });
  };

  onSelectedCourt = (event) => {
    //if user selected New Park then set isNewPark to true
    let isNewCourt = event.value === "New Court" ? true : false;
    let isCourtSelected = event.value === "New Court" ? false : true;

    //filter our table to the park we selected above
    let sensorStatus = this.state.courtStatus.filter(
      (court) => court.court_id === event.value
    );

    //filter to table for just court details
    //essentially a table of unique courts under out selected park
    let sensorDetails = sensorStatus.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.device_id === thing.device_id)
    );

    //pull the selected courts name
    let currentCourt = sensorStatus.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.court_id === thing.court_id)
    );

    if (isNewCourt === false) {
      this.setState({
        newPark: {
          ...this.state.newPark,
          court_id: currentCourt[0].court_id,
        },
      });
    } else {
      this.setState({
        newPark: {
          ...this.state.newPark,
          court_id: "",
        },
      });
    }

    //filter to only unique sensors under that selected court
    let sensorSearch = [
      ...new Set(sensorStatus.map((sensor) => sensor.device_id)),
    ];

    let sensorOptions = sensorSearch.map((d) => ({
      value: d,
      label: d,
    }));

    this.setState({
      selectedCourt: event,
      isNewCourt,
      isSensorDisabled: false,
      sensorDetails,
      sensorOptions,
      isCourtSelected,
    });
  };

  unix_convert = (timestamp) => {
    let unix_timestamp = timestamp;

    var d = new Date(unix_timestamp);

    var hours = d.getHours();
    var minutes = d.getMinutes();

    var date_format = d.toLocaleDateString("en-US");
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var formattedTime = date_format + " " + hours + ":" + minutes + " " + ampm;

    return formattedTime;
  };

  onSelectedSensor = async (event) => {
    // API call to pull last 10 events from selected sensor
    const devStatus = await axios.get(
      `${config.api_device.invokeUrlStatus}/${event.value}`
    );

    let sensorStatus = devStatus.data;

    // add a parameter to each object with a converted timestamp!
    sensorStatus.forEach((log) => {
      log["timestamp_convert"] = this.unix_convert(log.timestamp_unix_mqtt);
    });

    console.log(sensorStatus);

    this.setState({
      selectedSensor: event,
      isSensorSelected: true,
      refreshButton: false,
      sensorStatus,
    });
  };

  refreshSensor = () => {
    this.onSelectedSensor(this.state.selectedSensor);
  };

  customTheme = (theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: "orange",
        primary: "grey",
      },
    };
  };

  componentWillMount() {
    this.fetchParkInfo();
  }

  render() {
    //console.log(this.state.newPark);

    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <h1>Device History</h1>
            <p className="subtitle is-5">
              Troubleshoot device connectivity using the dropdowns below
            </p>
            <br />
            <div>
              <label className="label">Select a Park</label>
              <Select
                theme={this.customTheme}
                placeholder="Select a park"
                isSearchable
                options={this.state.parkOptions}
                value={this.state.selectedPark}
                onChange={this.onSelectedPark}
                autoFocus
              />
            </div>
            <br />
            {/* <p className="subtitle is-5">Current Areas </p>
                       {this.state.isParkSelected && (
              <div className="table-container">
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>Park</th>
                      <th>Area</th>
                      <th> Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.courtDetails.map((court) => (
                      <tr key={court.court_id}>
                        <td>{court.park_id}</td>
                        <td>{court.court_id}</td>
                        <td>{court.sensor_use}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )} */}
            <div>
              <label className="label">Select an Area</label>
              <Select
                theme={this.customTheme}
                placeholder="Select an area to see sensors"
                isSearchable
                isDisabled={this.state.isCourtDisabled}
                options={this.state.courtOptions}
                value={this.state.selectedCourt}
                onChange={this.onSelectedCourt}
              />
            </div>
            <br />
            {this.state.isCourtSelected && (
              <div className="table-container">
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>Park</th>
                      <th>Area</th>
                      <th>Sensor Name</th>
                      <th>Sensor ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sensorDetails.map((sensor) => (
                      <tr key={sensor.device_id}>
                        <td>{sensor.park_id}</td>
                        <td>{sensor.court_id}</td>
                        <td>{sensor.sensor_name}</td>
                        <td>{sensor.device_id}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            <div>
              <label className="label">Select a Sensor</label>
              <Select
                theme={this.customTheme}
                placeholder="Select a sensor to see recent history"
                isSearchable
                isDisabled={this.state.isSensorDisabled}
                options={this.state.sensorOptions}
                value={this.state.selectedSensor}
                onChange={this.onSelectedSensor}
              />
            </div>
            <br />
            <button
              className="button is-info is-small"
              onClick={this.refreshSensor}
              disabled={this.state.refreshButton}
            >
              Refresh
            </button>
            {this.state.isSensorSelected && (
              <div className="table-container">
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>Timestamp</th>
                      <th>Day</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sensorStatus.map((sensor) => (
                      <tr key={sensor.timestamp}>
                        <td>{sensor.timestamp_convert}</td>
                        <td>{sensor.day_of_week}</td>
                        <td>{sensor.Message}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      </Fragment>
    );
  }
}
