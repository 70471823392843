import React from "react";
import Icon1 from "../AboutPics/inactive-goal-side.png";
import Icon2 from "../AboutPics/active-goal-side.png";
import Icon3 from "../AboutPics/smartphone.png";
import Icon4 from "../AboutPics/10-minutes.png";

//https://www.flaticon.com/search?word=basketball&type=icon

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./AboutHowitWorksElements";

// can grab some good stock images from:
// https://undraw.co/illustrations
//

const AboutHowitWorks = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>How it Works</ServicesH1>
      <ServicesWrapper id="wrapper">
        <ServicesCard id="card1">
          <ServicesIcon src={Icon1} />
          <ServicesH2>Sensors on Equipment</ServicesH2>
          <ServicesP>
            Tracks real time motion and vibration of park equipment to broadcast
            availability
          </ServicesP>
        </ServicesCard>
        <ServicesCard id="card2">
          <ServicesIcon src={Icon2} />
          <ServicesH2>Sensors Detect an Event</ServicesH2>
          <ServicesP>
            Sensors send data wirelessly when motion or vibration is detected
          </ServicesP>
        </ServicesCard>
        <ServicesCard id="card3">
          <ServicesIcon src={Icon3} />
          <ServicesH2>Data Uploaded to the Cloud</ServicesH2>
          <ServicesP>
            The data is streamed to the cloud which updates the website in
            real-time
          </ServicesP>
        </ServicesCard>
        <ServicesCard id="card4">
          <ServicesIcon src={Icon4} />
          <ServicesH2>Assets Show Availability</ServicesH2>
          <ServicesP>
            After 10 minutes without any activity the assets will show as
            available
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default AboutHowitWorks;
