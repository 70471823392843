import React, { Component, Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { AiFillMinusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "../App.css";
//import "bootstrap/dist/css/bootstrap.min.css";
// brining in this whole stylesheet is impact our whole website!
// just apply the CSS styling that we need for bootstrap collapse
import Collapse from "react-collapse";

export default class CourtSensor extends Component {
  state = {
    yaxis: [],
    xaxis: [],
    device_id: this.props.device_id,
    goalStatus: null,
    timeSinceLast: null,
    most_recent: null,
    classes: null,
    yaxisDay: [],
    isSensorOpen: false,
  };
  //props is data we give to a component
  //state is data that is local or private to that component
  //not all components need a state, sometimes all thier data is given via props

  //this.setState({products: products})
  //if the key and the value you're updating to are the same, then you can simplify with
  //this.setState({ products })
  fetchSensors = async () => {
    try {
      //final layer of filter park> court> sensors
      //pre-filtered to the court from parent (Park)

      const latestSensor = this.props.latestSensorStatus.filter(
        (sensor) => sensor.sensor === this.props.device_id
      );

      //time comes from sensor in UTC - to Date will convert it to local browser time
      let latest_time = latestSensor[0].most_recent_activity;
      const goalStatus = latestSensor[0].status;
      const timeSinceLast = latestSensor[0].time_since_last;

      let most_recent = new Date(latest_time);

      let most_recent_month =
        most_recent.getMonth() +
        1 +
        "-" +
        most_recent.getDate() +
        "-" +
        most_recent.getFullYear() +
        " ";
      /* 
      let most_recent_timestamp =
        ("0" + most_recent.getHours()).slice(-2) +
        ":" +
        ("0" + most_recent.getMinutes()).slice(-2); */

      var hours = most_recent.getHours();
      var minutes = most_recent.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;

      /*       most_recent =
        most_recent.getMonth() +
        1 +
        "-" +
        most_recent.getDate() +
        "-" +
        most_recent.getFullYear() +
        " " +
        ("0" + most_recent.getHours()).slice(-2) +
        ":" +
        ("0" + most_recent.getMinutes()).slice(-2); */

      most_recent = most_recent_month + strTime;

      const parkStatus = this.props.parkStatus.filter(
        (sensor) => sensor.device_id === this.props.device_id
      );

      let yaxisDay = [];

      yaxisDay.push(
        parkStatus[0].Mon,
        parkStatus[0].Tues,
        parkStatus[0].Wed,
        parkStatus[0].Thurs,
        parkStatus[0].Fri,
        parkStatus[0].Sat,
        parkStatus[0].Sun
      );

      //days of the week on our X axis
      //xaxisDay = Object.keys(dayCounts);

      //now we get a count of events on each day of the week on our Y axis
      //let yaxisDay = Object.values(dayCounts);

      //is the most latest status passed down from our ancestor component

      //if available then color primary, else color warning
      let classes =
        "level-item court-status has-text-white has-text-centered notification is-";
      classes += goalStatus === "ACTIVE" ? "danger" : "success";

      this.setState({
        most_recent,
        timeSinceLast,
        goalStatus,
        classes,
        yaxisDay,
      });
    } catch (err) {
      console.log(`An error has occured: ${err}`);
    }
  };

  getGraph = () => {
    const series = [
      {
        name: "Popular Days",
        data: this.state.yaxisDay,
      },
    ];
    const options = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        tickAmount: 5,
        //can't make type as 'datetime' because the data format won't allow it!
        categories: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
        labels: {
          showDuplicates: false,
        },
      },
      yaxis: {
        show: false,
      },
    };

    return (
      <div>
        <br />
        <h2>Popular Days</h2>
        <br />
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height="250px"
        />
      </div>
    );
  };

  handleClick = () => {
    this.setState((prevState) => {
      return { isSensorOpen: !prevState.isSensorOpen };
    });
  };

  //componentWillMount - prepare for the first method, will run before the DOM is rendered
  //componentDidMount - runs after the DOM is rendered, any initialization that requires the dom should be done here
  // when we have our function in the DID MOUNT, then the dom will render first and THEN populate with data

  componentDidMount = () => {
    this.fetchSensors();
  };

  // passed the current modal state all the way down
  // if the parent modal state changed, then reset the sensorOpen to false
  // this will prevent it from showing if you close and re-open modal
  componentDidUpdate(prevProps) {
    if (this.props.modalState !== prevProps.modalState) {
      this.setState({
        isSensorOpen: false,
      });
    }
  }

  render() {
    //https://codepen.io/brussell98/pen/mEwxjP
    //expandable cards reference

    //set the key to modalState so that every time it changes this child component re-renders
    return (
      <Fragment>
        <div
          id="clickLevel"
          onClick={this.handleClick}
          className="level is-mobile sensor-click"
        >
          <p className="level-item">{this.props.sensor_name}</p>
          <div className={this.state.classes}>{this.state.goalStatus}</div>
          <div className="level-item">
            {this.state.isSensorOpen ? (
              <AiFillMinusCircle />
            ) : (
              <AiOutlinePlusCircle />
            )}
          </div>
        </div>
        <Collapse isOpened={this.state.isSensorOpen}>
          <div className="sensorDiv">
            <div className="tile is-child box is-vertical">
              <p className="is-size-6-mobile has-text-weight-bold has-text-centered">
                Time Since Active
              </p>
              <p className="is-size-7-mobile has-text-centered">
                {this.state.timeSinceLast}
              </p>
            </div>
            <div className="tile is-child box is-vertical">
              <p className="is-size-6-mobile has-text-weight-bold has-text-centered">
                Most Recent
              </p>
              <p className="is-size-7-mobile has-text-centered">
                {this.state.most_recent}
              </p>
            </div>
            <div>{this.getGraph()}</div>
          </div>
        </Collapse>
      </Fragment>
    );
  }
}
